<template>
<div>
    <el-card>
        <nav class="input" style="width: auto">
            <el-input v-model="search.name" size="medium" placeholder="姓名" />
        </nav>
        <nav class="input" style="width: auto">
            <el-input v-model="search.phone" size="medium" placeholder="电话" />
        </nav>
        <label for="" style="font-size: 14px; float: left; transform: translateY(9px)">认证时间：</label>

        <nav class="input">
            <el-date-picker v-model="search.certDate" type="daterange" format="yyyy-MM-dd" value-format="yyyy-MM-dd" style="width: 100%" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" size="medium" />
        </nav>
        <nav class="input" style="width: 150px">
            <el-button size="small" @click="reset">重置</el-button>
            <el-button type="primary" size="small" @click="subimt">{{
            '搜索'
          }}</el-button>
        </nav>
        </nav>
        <!-- <nav class="input" style="width: auto">
        <el-button type="primary" size="small"  @click="add">{{
          '添加商品'
        }}</el-button>
      </nav> -->
    </el-card>

    <el-card style="margin-top: 10px">
        <el-table :data="list" style="width: 100%" border stripe :header-cell-style="rowClass">
            <el-table-column label="ID" prop="id">

            </el-table-column>
            <el-table-column label="企业全称" prop="fullName">
            </el-table-column>
            <el-table-column label="执照" prop="license">
                <template slot-scope="{row}">
                    <el-image style="width: 80px;" :src=" row.license" :preview-src-list="[ row.license]">
                        <div slot="error">
                            <i class="el-icon-picture-outline" />
                        </div>
                    </el-image>
                </template>
            </el-table-column>
            <el-table-column label="管理员身份证" prop="authorizerCardFront">
                <template slot-scope="{row}">
                    <el-image style="width: 80px;" :src="row.authorizerCardFront" :preview-src-list="[row.authorizerCardFront]">
                        <div slot="error">
                            <i class="el-icon-picture-outline" />
                        </div>
                    </el-image><br>
                    <el-image style="width: 80px;" :src=" row.authorizerCardBack" :preview-src-list="[ row.authorizerCardBack]">
                        <div slot="error">
                            <i class="el-icon-picture-outline" />
                        </div>
                    </el-image><br>
                </template>
            </el-table-column>
            <el-table-column label="管理员姓名">
                <template slot-scope="{row}">
                    {{row.authorizer}}
                </template>
            </el-table-column>
            <el-table-column label="身份号码">
                <template slot-scope="{row}">
                    {{row.authorizerCardNum}}
                </template>
            </el-table-column>
             <el-table-column label="手机号">
                <template slot-scope="{row}">
                    {{row.authorizerPhone}}
                </template>
            </el-table-column>
            <el-table-column label="会员">
                <template slot-scope="{row}">
                    <el-image style="width: 80px;" :src="row.user.avatar" :preview-src-list="[row.user.avatar]">
                        <div slot="error">
                            <img :src="baseurl + yu + '/static/pc/img/avatar.png'" alt="" style="width: 80px;">
                        </div>
                    </el-image>
                    <div>
                        {{row.user.realName||row.user.nickname}}
                    </div>
                </template>
            </el-table-column>
            <el-table-column label="认领企业">
                <template slot-scope="{row}">
                    <el-image style="width: 80px;" :src="row.claimEnterprise.logo" :preview-src-list="[row.claimEnterprise.logo]">
                        <div slot="error">
                            <i class="el-icon-picture-outline" />
                        </div>
                    </el-image>
                    <div>
                        {{row.claimEnterprise.fullName}}
                    </div>
                </template>
            </el-table-column>

            <el-table-column label="支付状态">
                <template slot-scope="{row}">
                    <el-tag type="warning">{{row.payStatus==0?'未支付':'已支付'}}</el-tag>
                    <el-tag type="error" v-if="row.renewal == 1">续费</el-tag>

                </template>
            </el-table-column>
            <el-table-column label="认证状态">
                <template slot-scope="{row}">
                    <el-tag type="warning">{{row.status==0?'待审核':row.status==1?'审核通过':'审核不通过'}}</el-tag>
                    <div style="margin-top: 5px;">
                        {{row.remark}}
                    </div>
                </template>
            </el-table-column>
            <el-table-column label="认证时间" prop="certTime"></el-table-column>
            <el-table-column label="操作">
                <template slot-scope="{row}">
                    <el-button v-show="$store.state.btn_if.indexOf('user_enterprise_cert') != -1" type="text" icon="el-icon-edit" size="mini" class="skyblue" @click="audit(row)">审核通过</el-button>
                    <el-button  v-show="$store.state.btn_if.indexOf('user_enterprise_cert') != -1" type="text" icon="el-icon-edit" size="mini" style="color:red" @click="audit_f(row)">审核不通过</el-button>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination :current-page="search.page" background :page-sizes="[3, 5, 10, 15]" :page-size="search.pageSize" layout="total, prev, pager, next" :total="total" @current-change="handleCurrentChange" />
    </el-card>
    <failing :type="2" :dialog-visible.sync="p_a" :form="p_a_f" @getlist="getlist" />
    <pop ref="pop" @toAudit="toAudit" title="请确认数据库是否有该用户认证的企业">
        <el-form :model="form" label-width="auto">
            <el-form-item>
                <!-- <el-input v-model="form.enterpriseId"></el-input> -->
                <fuzzy-selection type="1" all tipName="确认库里没有该企业可以不填。" clearable :value.sync="form.enterpriseId"></fuzzy-selection>
            </el-form-item>
            <div class="tip waring">
                该企业不是通过认领提交的，请再次确认我们库里是否有该企业，否则会造成企业数据冗余。
            </div>
        </el-form>
    </pop>
</div>
</template>

<script>
import list from '/src/mixin/list.js'
import fuzzySelection from '../../components/fuzzySelection'
export default {
    mixins: [list],
    components: {
        failing: () => import('./enterprise_cert_list/failing.vue'),
        fuzzySelection,
    },
    data() {
        return {
            search: {
                name: '',
                page: 1,
                pageSize: 10,
                phone: '',
                certDate: [],
            },
            p_a: false,
            p_a_f: null,
            form: {
                enterpriseId: ''
            }
        }
    },
    created() {
        this.getlist();
    },
    methods: {
        add() {},
        async toAudit(row) {
            const {
                data: res
            } = await this.$http.post('/admin/Enterprise/cert', {
                id: row.id,
                status: 1,
                enterpriseId: this.form.enterpriseId
            })
            if (res.errorCode == 200) {
                this.$message.success(res.message)
                this.$refs.pop.show = false
                this.getlist()
            }
        },
        
       async toTaudit(row) {
        //    row.renewal=1
        //    console.log(row.renewal);
            if (!row.claimEnterpriseId&&row.renewal != 1&&row.user.haveEnterprise != 1) {
                this.$refs.pop.show = true
                this.$refs.pop.row = row
                return
            }
            this.$confirm('请确认审核通过?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(async () => {
                // return
                const {
                    data: res
                } = await this.$http.post('/admin/Enterprise/cert', {
                    id: row.id,
                    status: 1
                })
                if (res.errorCode == 200) {
                    this.$message.success(res.message)
                    this.getlist()
                }
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消'
                });
            });
        },
        async audit(row) {
            // if (row.payStatus == 0) {
            //     try {
            //         await this.$confirm('该用户未支付认证费用，审核通过则代表该用户不需要支付费用?', '提示', {
            //             confirmButtonText: '确定',
            //             cancelButtonText: '取消',
            //             type: 'warning'
            //         })
            //         this.toTaudit(row)
            //     } catch (error) {
            //         this.$message({
            //             type: 'info',
            //             message: '已取消'
            //         });
            //     }
            //     return
            // }
            this.toTaudit(row)
        },
        audit_f(row) {
            // console.log(row);
            this.p_a = true
            this.p_a_f = row
            // const {data:res} =  await this.$http.post('/admin/Enterprise/cert',{
            //        id:row.id,
            //        status:2
            //    })
        },
        async getlist() {
            const {
                data
            } = await this.$http.get('/admin/Enterprise/getEnterpriseCertList', {
                params: {
                    ...this.search,
                    certDate: this.search.certDate.join('~')
                }
            })
            this.list = data.data.list
            this.total = data.data.totalCount
        },
        subimt() {
            this.getlist()
        }
    },
}
</script>

<style lang="less" scoped>

</style>
